/** @jsx jsx */
import { jsx } from '@emotion/core';

// Import libraries
import uuid4 from 'uuid/v4';
import { useState, useEffect, useCallback, useMemo } from 'react';

// Import Ant Design components
import { Empty, Typography, Row, Col, Spin, Button, Select, Modal, Divider, Alert } from 'antd';

// Import components
import Channel from '../Channel';

// Import utilities
import { statusRender } from '../../../Devices/utils';

// Import constants
import { OTMActionCodes, SocketIOChannels } from '../../../../constants/requestHub';

// Import styles
import styles from './styles';

// Import additional Ant Design components
const { Text } = Typography;

const Results = ({ vehicle, searchDate, deviceHDD, data, reqHubSocket, onRequestCreated }) => {
    // Extract values from vehicle
    const { deviceId, online } = vehicle ?? {};

    // Initialisation
    const [channels, setChannels] = useState([]);
    const [preview, setPreview] = useState({});
    const [loadingImage, setLoadingImage] = useState(false);
    const [selectedChannel, setSelectedChannel] = useState(null);
    const [noDataDetected, setNoDataDetected] = useState(false);

    const [imageRequestId, setImageRequestId] = useState(null);
    const [requestingVideo, setRequestingVideo] = useState(false);

    const hddStatus = useMemo(() => deviceHDD?.split('|'), [deviceHDD]);

    const getChannelImages = useCallback(
        (time, channels) => {
            if (reqHubSocket && deviceId) {
                setNoDataDetected(false);
                setLoadingImage(true);

                // Prepare a new request ID
                const requestId = uuid4();
                setImageRequestId((currentReqId) => {
                    if (currentReqId) {
                        reqHubSocket.off(`${SocketIOChannels.REQUEST}:${currentReqId}`);
                    }
                    return requestId;
                });

                // Get channel images for a specific time
                const ts = Math.floor(time / 1000);
                reqHubSocket.emit(SocketIOChannels.REQUEST, {
                    deviceId,
                    actionCode: OTMActionCodes.OBTAIN_HISTORY_IMAGES,
                    requestId: requestId,
                    s: ts,
                    e: ts,
                    chs: channels,
                });
            }
        },
        [reqHubSocket, deviceId]
    );

    const handleRequestVideo = useCallback(
        (startTime, endTime, channels) => {
            if (reqHubSocket && deviceId) {
                setRequestingVideo(true);

                // Prepare a new request ID
                const requestId = uuid4();

                // Prepare request data
                const requestData = {
                    deviceId,
                    actionCode: OTMActionCodes.REQUEST_VIDEO,
                    requestId: requestId,
                    d: {
                        chs: channels,
                        s: Math.floor(startTime / 1000),
                        e: Math.floor(endTime / 1000),
                        w: false,
                    },
                };

                // Request a video from device
                reqHubSocket.emit(SocketIOChannels.REQUEST, requestData);

                reqHubSocket.on(`${SocketIOChannels.REQUEST}:${requestId}`, (data) => {
                    setRequestingVideo(false);

                    // Handle errors
                    if (
                        !data?.downloadIds ||
                        !data?.downloadRequestId ||
                        data?.statusCode === 5404
                    ) {
                        Modal.error({
                            content: 'No footage found, please choose another date and time.',
                            centered: true,
                        });
                    } else {
                        onRequestCreated({
                            requestId: data?.downloadRequestId ?? '',
                            downloadIds: data?.downloadIds ?? {},
                            queryStartTime: requestData.d.s,
                            queryEndTime: requestData.d.e,
                        });
                    }

                    reqHubSocket.off(`${SocketIOChannels.REQUEST}:${requestId}`);
                });
            }
        },
        [reqHubSocket, deviceId]
    );

    useEffect(() => {
        if (online && deviceId && data) {
            // Set channels
            setChannels(
                Object.keys(data).map((channel) => ({
                    name: channel,
                    displayName: `Channel ${channel}`,
                }))
            );
            setSelectedChannel(null);

            // Initialise preview images
            setPreview(
                Object.keys(data).reduce((acc, channel) => {
                    acc[channel] = '/imgs/ic_loading.png';
                    return acc;
                }, {})
            );

            if (Object.keys(data).length > 0) {
                // Pick a timestamp from the first channel
                const recordingTimes = Object.values(data)[0];
                const [startTime, endTime] = recordingTimes[Math.floor(recordingTimes.length / 2)];
                const timestamp = Math.floor((startTime + endTime) / 2);

                // Get channel images
                getChannelImages(
                    timestamp,
                    Object.keys(data).map((channel) => parseInt(channel, 10))
                );
            }
        }
    }, [online, data, getChannelImages]);

    useEffect(() => {
        if (reqHubSocket && imageRequestId) {
            reqHubSocket.on(
                `${SocketIOChannels.REQUEST}:${imageRequestId}`,
                ({ statusCode, data }) => {
                    setPreview((current = {}) => {
                        const images = Object.entries(current).reduce((acc, [channel, image]) => {
                            const base64Data = data?.[0]?.[`ch${channel}`];
                            if (statusCode === 5404 || !base64Data) {
                                acc[channel] = '/imgs/ic_nodata.png';
                                setNoDataDetected(true);
                            } else {
                                acc[channel] = `data:image/png;base64, ${base64Data}`;
                            }

                            return acc;
                        }, {});
                        return images;
                    });

                    setLoadingImage(false);
                    setImageRequestId(null);
                }
            );
        }

        return () => {
            if (reqHubSocket && imageRequestId) {
                reqHubSocket.off(`${SocketIOChannels.REQUEST}:${imageRequestId}`);
            }
        };
    }, [reqHubSocket, imageRequestId]);

    // Handle no data and offline states
    if (!data) {
        return <Empty description="Make a search to view results" style={{ marginTop: '80px' }} />;
    } else if (Object.keys(data).length === 0) {
        return (
            <Empty
                description="No recordings found"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                css={{ marginTop: '100px' }}
            />
        );
    } else if (!online) {
        return (
            <Empty
                description="Device is offline"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                css={{ marginTop: '100px' }}
            />
        );
    }

    // Show channel details
    if (selectedChannel && data[selectedChannel].length > 0) {
        return (
            <div>
                <Divider />

                <div css={styles.header}>
                    <Button
                        type="link"
                        onClick={() => setSelectedChannel(null)}
                        css={styles.back}
                        disabled={requestingVideo}
                    >
                        Back to Channels
                    </Button>

                    {statusRender({ hdd: deviceHDD }, true, true)}
                </div>

                <Channel
                    searchDate={searchDate}
                    name={selectedChannel}
                    preview={preview[selectedChannel]}
                    data={data[selectedChannel]}
                    loadingImage={loadingImage}
                    requestingVideo={requestingVideo}
                    handleRequestVideo={handleRequestVideo}
                    channels={channels}
                    selectedChannel={selectedChannel}
                    onChannelChange={setSelectedChannel}
                />
            </div>
        );
    }

    return (
        <div>
            <Divider />

            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 30px' }}>
                <Text type="secondary">Found recordings from {channels.length} channels</Text>
                {statusRender({ hdd: deviceHDD }, true, true)}
            </div>

            <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                {channels.map((channel) => (
                    <Col
                        key={channel.name}
                        span={6}
                        onClick={() => setSelectedChannel(channel.name)}
                        css={styles.channel}
                    >
                        <Spin spinning={loadingImage}>
                            <img
                                src={preview[channel.name]}
                                alt="Channel preview"
                                css={styles.preview}
                                onError={(ev) => (ev.target.src = '/imgs/ic_nosignal.png')}
                            />
                        </Spin>
                        <Text strong>{channel.displayName}</Text>
                    </Col>
                ))}
            </Row>

            {hddStatus?.[1] !== 'S1' ? (
                <Alert
                    message="Previews unavailable due to possible SD card damage. You may still request videos by selecting a channel above."
                    type="warning"
                    showIcon
                    style={{ marginTop: '30px' }}
                />
            ) : noDataDetected ? (
                <Alert
                    message="Previews unavailable but you may still request videos by selecting a channel above."
                    type="warning"
                    showIcon
                    style={{ marginTop: '30px' }}
                />
            ) : null}
        </div>
    );
};

export default Results;
